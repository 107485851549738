<div class="casino-offers">
  <p *ngIf="!isAnyOfferSelected" [innerHTML]="'no_casino_selected' | translate" class="choose-offer-text"></p>
  <p *ngIf="isSelectAllDisabled" [innerHTML]="'all_casinos_selected' | translate" class="choose-offer-text"></p>
  <div class="slider-gradient-left"></div>
  <swiper-container
    init="false"
    #mySwiper
    [ngClass]="casinos.length <= 3 ? 'swiper-slider-' + casinos.length : 'swiper-slider'"
    navigation="true"
    pagination="true"
  >
    <swiper-slide *ngFor="let casino of casinos">
      <div (click)="checkCasino(casino)" class="slider-item">
        <div class="slider-item__inner-card-wrapper">
          <div [ngClass]="getCasinoStatusClass(casino)" class="slider-item__inner-card">
            <img class="casino-agb__logo-img" [src]="casino.logo | safeUrl" />
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
  <div class="slider-gradient-right"></div>
  <p class="casino-offers__agb-text" [innerHTML]="'agb_casino_offers_text' | translate"></p>
  <div class="casino-offers__buttons-wrapper">
    <button
      class="select-all-casino-offers"
      (click)="selectAll()"
      [innerHTML]="'select_all_button_text' | translate"
      [disabled]="isSelectAllDisabled"
    ></button>
    <app-vs-continue-button
      (click)="confirmStepThree()"
      [isSubmitting]="isSubmitting"
      [isDisabled]="isSelectAllDisabled || registrationForm?.invalid"
    ></app-vs-continue-button>
  </div>
</div>
