import { Component, HostListener, Renderer2 } from '@angular/core';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import { environment } from '@env/environment';
import { globalConfig } from '@config/config.global';
import { localConfig } from '@config/config.local';
import { PageReloadService } from '@app/shared/services/page-reload.service';
import { removeTmpSession } from '@app/shared/helpers/auth';
import { PreloadService } from '@app/shared/services/preload.service';
import { Router } from '@angular/router';
import { HelperService } from '@app/shared/services/helper.service';
import { Theme, ThemeName } from '@themes/models/theme';
import { ThemeService } from '@app/modules/layout/services/theme.service';
import { TranslationService } from './shared/services/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'vision';
  theme: Theme;

  constructor(
    private projectConfigService: ProjectConfigService,
    private pageReload: PageReloadService,
    private preloadService: PreloadService,
    private router: Router,
    private helperService: HelperService,
    private themeService: ThemeService,
    private renderer: Renderer2,
    private translationService: TranslationService
  ) {
    this.projectConfigService.initConfig({
      ...globalConfig,
      ...environment,
      ...localConfig
    });

    console.log('ENVIRONMENT:', environment.environment);

    this.themeService.getTheme().subscribe(theme => {
      this.theme = theme;

      Object.values(ThemeName).forEach(themeName => {
        this.renderer.removeClass(document.body, themeName);
      });

      this.renderer.addClass(document.body, theme.name);
    });

    this.title = environment.project;
    this.preloadService.preloadResources();
    this.translationService.initLangLoaded.subscribe(loaded => {
      if (loaded) {
        this.preloadService.cacheInitialTranslations();
      }
    });

    // on each reload remove tms customer session data
    removeTmpSession();

    const emailToken = this.helperService.getEmailTokenFromUrl();
    emailToken
      ? this.router.navigate(['/welcome'], {
          queryParams: { token: emailToken },
          queryParamsHandling: 'merge'
        })
      : this.router.navigate(['/home'], { queryParamsHandling: 'merge' });
  }

  @HostListener('document:click', ['$event'])
  clickEvent() {
    this.pageReload.updateLastInteractionTime();
  }

  @HostListener('document:touchstart', ['$event'])
  touchstartEvent() {
    this.pageReload.updateLastInteractionTime();
  }
}
