import { Injectable } from '@angular/core';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  languages: any[];
  onLangChange = new Subject();
  private selectedLanguage = '';
  public initLangLoaded = new BehaviorSubject<boolean>(false);

  constructor(private config: ProjectConfigService, private translate: TranslateService) {
    // get all languages from config
    this.languages = this.config.getConfig('languages');
  }

  setDefault(): void {
    // set available languages for page
    this.translate.addLangs(this.getLanguagesIdoCode());

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(this.getUserLanguage() || this.translate.getBrowserLang() || 'en');

    // set default selection
    this.selectedLanguage = this.getCurrentLanguage();

    // detect language changes
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.selectedLanguage !== event.lang) {
        this.selectedLanguage = event.lang;
        this.onLangChange.next(this.selectedLanguage);
      }
    });
  }

  getLanguagesIdoCode(): Array<string> {
    return [...this.languages].map(language => language.iso);
  }

  getLanguageList(): any {
    return this.languages;
  }

  getCurrentLanguage(): string {
    return this.translate.currentLang;
  }

  getCurrentLanguageId(): number {
    const currentLang = [...this.languages].find(language => language.iso === this.getCurrentLanguage());
    return currentLang.languageId;
  }

  getUserLanguage(): string | null {
    return localStorage.getItem('lang');
  }

  setUserLanguage(lang: string, initCall?: boolean): void {
    // update translate service
    this.translate.use(lang).subscribe(res => {
      if (res && initCall) {
        this.initLangLoaded.next(true);
      }
    });
    // store selection in local storage
    localStorage.setItem('lang', lang);
  }
}
