<mat-form-field
  autocomplete="off"
  appearance="standard"
  [ngClass]="{
    'valid-input': (formControl.valid && formControl.touched) || formControl.disabled,
    'invalid-input': formControl.invalid && formControl.touched
  }"
  [formGroup]="registrationForm"
>
  <mat-label>{{ config.label | translate }}</mat-label>
  <input
    matInput
    [formControlName]="config.key"
    [type]="passwordVisibility ? 'text' : config.type"
    (focusin)="onFocusInOut('in', $event)"
    (focusout)="onFocusInOut('out', $event)"
    [matAutocomplete]="auto"
    #formInput
    [formControl]="formControl"
    [id]="config.key"
    (copy)="(false)"
    (paste)="(false)"
  />
  <div
    *ngIf="formControl.value"
    style="cursor: pointer; display: flex; flex-direction: row; flex-shrink: 1"
    matSuffix
    aria-label="Clear"
  >
    <mat-icon
      *ngIf="config.type === 'password'"
      (click)="passwordVisibility = !passwordVisibility; $event.stopPropagation()"
    >
      {{ passwordVisibility ? 'visibility_off' : 'visibility' }}
    </mat-icon>
    <mat-icon (click)="resetInput()" *ngIf="!formControl.disabled">close</mat-icon>
  </div>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      style="height: 100%; padding: 0; margin: 0"
      *ngIf="config.registrationPassword"
      (click)="setRandomGeneratedPassword()"
    >
      <app-password-suggestion-card (passwordSelection)="getRandomStrongPassword($event)">
      </app-password-suggestion-card>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="formControl.invalid && !isPasswordField">
    <span *ngIf="!this.hasLabelHidden" [innerHTML]="this.config.label | translate"></span>&nbsp;
    <span [innerHTML]="getErrorMessage() | translate"></span>
  </mat-error>
</mat-form-field>
<app-vs-validation-errors
  *ngIf="config.validators && showValidationLabels"
  [validationErrors]="getInputValidationErrors()"
  [config]="config"
>
</app-vs-validation-errors>
