import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { Router } from '@angular/router';
import { CustomerService } from '@app/modules/customer/services/customer.service';
import { HelperService } from '@app/shared/services/helper.service';
import { take } from 'rxjs/operators';
import { DataShareService } from '@app/shared/services/data-share.service';
import { forkJoin } from 'rxjs';
import { partner } from '@partners/partner';
import { TranslationService } from '@app/shared/services/translation.service';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent implements OnInit {
  showContent = false;
  succes: boolean;
  hasLimit: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private customerService: CustomerService,
    private helperService: HelperService,
    private dataShareService: DataShareService,
    private translationService: TranslationService
  ) {
    this.translationService.setUserLanguage('de');
  }

  ngOnInit(): void {
    this.authService
      .EmailTokenLogin()
      .then(() => {
        this.succes = true;
        const customerId = localStorage.getItem('customer_id');
        forkJoin({
          casinosResult:
            // TODO: Revert after ICE
            partner.name === 'tipwin'
              ? this.customerService.getCasinosAgbDataDone({
                  customer_id: customerId
                })
              : this.customerService.getCasinosAgbData({
                  customer_id: customerId
                }),
          limitResult: this.customerService.customerHasLimit({ customer_id: customerId })
        })
          .pipe(take(1))
          .subscribe(response => {
            this.hasLimit = response.limitResult.has_limit;
            this.dataShareService.updateCasinoList(response.casinosResult.casinos);
          });
      })
      .catch(() => {
        this.succes = false;
      });
  }

  navigateToNextScreen(): void {
    const emailToken = this.helperService.getEmailTokenFromUrl();

    const url = '/app/customer/casinos-agb';
    this.router.navigate([url], {
      queryParams: { token: emailToken },
      queryParamsHandling: 'merge'
    });
  }
}
