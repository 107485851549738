<button
  class="overlay_demo"
  [ngClass]="!showDemo ? 'green' : 'white'"
  *ngIf="(wandaEnvironment === 'demo' || wandaEnvironment === 'dev') && translationsLoaded"
  (click)="showDemo = !showDemo"
>
  <span *ngIf="showDemo" [innerHtml]="'demo_live' | translate"></span>
  <span *ngIf="!showDemo" [innerHtml]="'demo_sales' | translate"></span>
</button>
<ng-container *ngIf="translationsLoaded && showTabs">
  <div class="demo_page" *ngIf="showDemo" [ngClass]="showDemo ? 'fadeInDemo' : 'fadeOutDemo'">
    <iframe
      id="demo_iframe"
      width="1920px"
      height="1080px"
      frameBorder="0"
      [src]="url | safeUrl"
      loading="lazy"
    ></iframe>
  </div>
  <div class="main-page" *ngIf="!showDemo" [ngClass]="!showDemo ? 'fadeInDemo' : 'fadeOutDemo'">
    <div class="main-header-navigation">
      <div class="header-navigation__logo">
        <div class="header-navigation__logo">
          <img src="./assets/logo/header-logo.png" alt="" />
        </div>
      </div>
      <div class="header-navigation__buttons">
        <div matRipple [matRippleColor]="matRippleColor" class="header-navigation__btn" (click)="openHelpPopup()">
          <img [src]="'assets/icons/icn_info_w.png'" alt="info" class="header-navigation__icon" />
        </div>
        <div
          matRipple
          [matRippleColor]="matRippleColor"
          class="header-navigation__btn"
          routerLink="/app/customer/login"
        >
          <img [src]="'assets/icons/icn_user_w.png'" alt="info" class="header-navigation__icon" />
        </div>
      </div>
      <div></div>
      <div class="language-list-container">
        <div
          *ngIf="!isLanguageSwitchOpen"
          (click)="isLanguageSwitchOpen = true"
          class="header-navigation__current-lang"
        >
          <div
            [ngClass]="['lang__btn-flag', 'lang__btn-flag--' + currentLang, 'lang__flag', 'lang__flag--' + currentLang]"
          ></div>
        </div>
        <div *ngIf="isLanguageSwitchOpen" class="language-list">
          <div
            *ngFor="let language of languages"
            [ngClass]="['lang__flag lang__flag--' + language.iso]"
            (click)="setLanguage(language.iso)"
          ></div>
        </div>
      </div>
    </div>
    <div class="main-nav-container">
      <div class="dashboard-rows">
        <div class="dashboard-tabs" [ngClass]="tickerEnabled ? '' : 'no-ticker'" *ngIf="translationsLoaded">
          <div
            class="dashboard-tabs__tab"
            [ngClass]="{
              'regist-bg': this.environment.environment !== 'demo',
              'regist-bg-demo': this.environment.environment === 'demo'
            }"
            routerLink="/app/registration"
          >
            <div class="yellow-sticker">
              <span [innerHTML]="'mainpage_yellow_info' | translate"></span>
            </div>
            <div class="dashboard-tabs__tab__tab-registrieren">
              <img [src]="'assets/icons/register.svg'" />
              <p [innerHTML]="'registration' | translate"></p>
              <button class="btn-yellow" [innerHTML]="'now_register' | translate"></button>
            </div>
          </div>
          <div class="dashboard-tabs__tab paylado-bg">
            <div class="dashboard-tabs__tab__tab-paylado">
              <img [src]="'assets/icons/paylado-wallet.svg'" />
              <p [innerHTML]="'mainpage_paylado_text' | translate"></p>
              <button
                class="btn-transparent"
                routerLink="/app/paylado"
                [innerHTML]="'paylado_btn' | translate"
              ></button>
            </div>
          </div>
          <div class="dashboard-tabs__tab polar-bg" routerLink="/app/customer/login">
            <div class="dashboard-tabs__tab__tab-polar">
              <img [src]="'assets/icons/icn_registrate.svg'" />
              <p [innerHTML]="'mainpage_polar_text' | translate"></p>
              <button
                class="btn-yellow"
                [innerHTML]="'mainpage_polar_button' | translate"
                [disabled]="!ecommerceEnabled"
                (click)="goToEcommerce()"
              ></button>
            </div>
          </div>
        </div>
        <div *ngIf="tickerEnabled" id="ticker-container"><app-ticker></app-ticker></div>
      </div>
    </div>

    <div class="footer">
      <div class="logo-multilog">
        <img src="./assets/logo/logo-multilog.png" alt="" srcset="" />
      </div>
      <div class="footer-message">
        <span class="footer-message__text" [innerHTML]="getTranslationByTag('footer_text_message')"> </span>
      </div>
    </div>

    <div *ngIf="themeSwitcherEnabled" style="position: absolute; bottom: 0; left: 0">
      <app-theme-switcher></app-theme-switcher>
    </div>
  </div>
</ng-container>
