import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';
import { LoginForm } from '@app/modules/registration/types/login-form';

export const EMAILKEY = 'email';
export const EMAILTYPE = 'email';
export const PHONENUMBERKEY = 'phone_number';
export const PHONENUMBERTYPE = 'phone-number';
export const TEXTTYPE = 'text';
export const NUMBERKEY = 'number';
export const SMSVERIFICATIONCODEKEY = 'sms_verification_code';
export const LOGIN_ERRORS = {
  INVALID_OTP: 'authentication fail. otp invalid #7',
  TOKEN_EXPIRED: 'authentication fail. token expired',
  CUSTOMER_NOT_FOUND: 'customer not found'
};

export const loginFormFields: LoginForm = {
  stepOne: [
    {
      key: EMAILKEY,
      label: __('email_or_phone_number'),
      type: TEXTTYPE,
      required: true,
      validators: {
        phoneLogin: true
      }
    }
  ],
  stepTwo: [
    {
      key: SMSVERIFICATIONCODEKEY,
      label: __('sms_verification_code'),
      type: TEXTTYPE,
      required: true,
      loginVerification: true,
      validators: {
        smsCodeInvalid: true
      }
    }
  ]
};
