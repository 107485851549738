import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';

export function getErrorMessage(currentError: string): string {
  const errorMessages = {
    minlength: __('min_required_characters_10'),
    required: __('error_is_required'),
    email: __('error_invalid_format'),
    passwordMismatch: __('error_pwd_mismatch'),
    emailExist: __('error_email_exist'),
    emailOrNumberExists: __('error_email_or_phone_exist'),
    emailsMismatch: __('error_email_mismatch'),
    passwordLowerCaseMissing: __('error_lowercase'),
    passwordUpperCaseMissing: __('error_uppercase'),
    passwordSpecialCharMissing: __('error_special_character'),
    passwordNumberMissing: __('error_numbers_required'),
    ageRestricted: __('age_restriction'),
    invalidPhoneNumber: __('invalid_phone_number'),
    hasNumber: __('error_wrong_character_in_field'),
    phonePatternInvalid: __('error_wrong_character_in_field'),
    smsCodeInvalid: __('error_invalid_sms_code')
  };
  return errorMessages[currentError];
}
