import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';
import { TForm } from '@app/shared/types/typed-form';
import { phoneVerificationFormFieldsType } from '../../../modules/registration/types/phone-verification-form-type';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { RegistrationService } from '../../../modules/registration/services/registration.service';
import { Subscription } from 'rxjs';
import { FormInput } from '@app/shared/models/form-input';
import { NotificationService } from '@app/shared/services/notification.service';
import {
  DIALCODEKEY,
  PHONENUMBERKEY,
  PHONENUMBERTYPE,
  SMSVERIFICATIONCODEKEY,
  VERIFIEDPHONENUMBERKEY
} from '@app/modules/registration/registration.model';
import { SendSmsCodeEnum } from '@app/shared/models/phone-number';
import { NgOtpInputComponent, NgOtpInputConfig } from 'ng-otp-input';
import { HelperService } from '@app/shared/services/helper.service';
import { EMAILKEY } from '@app/modules/registration/login.model';

@Component({
  selector: 'app-vs-phone-verification',
  templateUrl: './vs-phone-verification.component.html',
  styleUrls: ['./vs-phone-verification.component.scss']
})
export class VsPhoneVerificationComponent implements OnInit, OnDestroy {
  @Input() registrationFormStepOne: FormGroup;
  @Input() registrationForm: FormGroup;
  @Input() config: FormInput;
  @Input() phoneNumberConfig: FormInput;
  @Input() phoneNumberForm: FormGroup;
  @Input() componentCaller: string;
  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput: NgOtpInputComponent;

  public phoneVerificationForm: TForm<phoneVerificationFormFieldsType>;
  private allSubscriptions: Subscription[] = [];
  public phoneNumberType: string = PHONENUMBERTYPE;
  public codeControl: AbstractControl;
  public otpFlag: boolean = false;
  public otpResend: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private registrationService: RegistrationService,
    private notificationService: NotificationService,
    private helperService: HelperService,
    private cdRef: ChangeDetectorRef
  ) {
    this.phoneVerificationForm = this.formBuilder.group({}) as TForm<phoneVerificationFormFieldsType>;
  }

  otpConfig: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: true,
    placeholder: '-',
    inputStyles: {
      width: '55px',
      height: '85px',
      'border-radius': '18px',
      'text-align': 'center',
      'font-size': '36px',
      'margin-right': '18px',
      'background-color': 'RGB(196,196,196)'
    },
    inputClass: 'each_input',
    containerStyles: {
      display: 'flex',
      'justify-content': 'center'
    }
  };

  ngOnInit(): void {
    if (this.registrationForm.get(SMSVERIFICATIONCODEKEY)) {
      this.registrationForm.get(SMSVERIFICATIONCODEKEY).setErrors({ required: true });
    }
  }

  onFocus(action: string, event: FocusEvent): void {
    const arrayInput = document.getElementsByClassName('each_input') as HTMLCollectionOf<HTMLInputElement>;

    for (var i = 0; i < arrayInput.length; i++) {
      arrayInput[i].classList.remove('green_border');
      arrayInput[i].classList.remove('red_border');
    }
    const targetInput = event.target as HTMLInputElement;
    if (action === 'in') {
      targetInput.setAttribute('placeholder', '');
    } else {
      targetInput.setAttribute('placeholder', '-');
    }
  }

  ngAfterViewInit(): void {
    const keyPressSub = this.helperService.keyPressed.subscribe(res => {
      const regexOtpIds = /otp_([0-9]+)_/;
      const currentElement = document.activeElement as HTMLInputElement;

      if (!currentElement.id.match(regexOtpIds)) {
        const arrayInput = document.getElementsByClassName('each_input') as HTMLCollectionOf<HTMLInputElement>;
        arrayInput[0].focus();
      }
      this.registrationForm.get(SMSVERIFICATIONCODEKEY).setValue(null);
      const activeOtpBox = document.activeElement as HTMLInputElement;

      const match = activeOtpBox.id.match(regexOtpIds);

      const currentNumber = parseInt(match[1]);

      let otpValuesCombined = Array.from(activeOtpBox.parentElement.children)
        .map(el => (el as HTMLInputElement).value)
        .join('');

      if (res == '{backspace}') {
        if (activeOtpBox.value && match) {
          activeOtpBox.value = '';
          return;
        }
        activeOtpBox.value = '';
        const newNumber = currentNumber == 1 ? 0 : currentNumber - 1;
        const updatedString = activeOtpBox.id.replace(regexOtpIds, `otp_${newNumber}_`);
        this.ngOtpInput.focusTo(updatedString);
      } else {
        activeOtpBox.value = res;

        const newNumber = currentNumber + 1;
        const updatedString = activeOtpBox.id.replace(regexOtpIds, `otp_${newNumber}_`);
        this.ngOtpInput.focusTo(updatedString);
      }
      otpValuesCombined = Array.from(activeOtpBox.parentElement.children)
        .map(el => (el as HTMLInputElement).value)
        .join('');

      if (otpValuesCombined.length === 4) {
        Array.from(activeOtpBox.parentElement.children).forEach(el =>
          (el as HTMLInputElement).classList.add('green_border')
        );

        this.registrationForm.get(SMSVERIFICATIONCODEKEY).setValue(otpValuesCombined);
      } else {
        Array.from(activeOtpBox.parentElement.children).forEach(el => {
          (el as HTMLInputElement).classList.remove('green_border');
          (el as HTMLInputElement).classList.remove('red_border');
        });
        this.registrationForm.get(SMSVERIFICATIONCODEKEY).setValue(null);
      }
    });

    this.allSubscriptions.push(keyPressSub);

    this.cdRef.detectChanges();

    this.registrationForm.get(SMSVERIFICATIONCODEKEY).statusChanges.subscribe(res => {
      if (res === 'INVALID' && this.registrationForm.get(SMSVERIFICATIONCODEKEY).value?.length === 4) {
        const arrayInput = document.getElementsByClassName('each_input') as HTMLCollectionOf<HTMLInputElement>;

        for (var i = 0; i < arrayInput.length; i++) {
          arrayInput[i].classList.remove('green_border');
          arrayInput[i].classList.add('red_border');
          arrayInput[i].value = '';
        }
      }
    });
  }

  ngAfterViewChecked(): void {
    const firstElement = this.ngOtpInput.getBoxId(0);
    const first = document.getElementById(firstElement);
    if (first && !this.otpFlag && this.config.key == 'sms_verification_code') {
      first.focus();
      this.componentClicked();
      this.otpFlag = true;
      this.otpResend = false;
    }
    this.cdRef.detectChanges();
  }

  get nestedRegistrationForm(): FormGroup {
    return this.registrationForm.get(VERIFIEDPHONENUMBERKEY) as FormGroup;
  }

  get phoneNumberFormGroup(): FormGroup {
    if (this.componentCaller == 'login') {
      return this.registrationFormStepOne.get(EMAILKEY) as FormGroup;
    } else if (this.componentCaller == 'register') {
      return this.registrationFormStepOne.get(PHONENUMBERKEY) as FormGroup;
    } else {
      return null;
    }
  }

  get dialCode(): string {
    if (this.componentCaller == 'login') {
      return '';
    } else if (this.componentCaller == 'register') {
      return this.phoneNumberFormGroup.get('dialCode').value;
    } else {
      return null;
    }
  }

  get number(): string {
    if (this.componentCaller == 'login') {
      return '';
    } else if (this.componentCaller == 'register') {
      return this.phoneNumberFormGroup.get('number').value;
    } else {
      return null;
    }
  }

  get email(): string {
    if (this.componentCaller == 'login') {
      return this.registrationFormStepOne.get(EMAILKEY).value;
    } else if (this.componentCaller == 'register') {
      return '';
    } else {
      return null;
    }
  }

  get phoneNumber(): string {
    if (this.componentCaller == 'login') {
      return this.email;
    } else if (this.componentCaller == 'register') {
      const dialCode = this.dialCode;
      const number = this.number;
      const fullNumber = '+' + dialCode + number;
      return fullNumber;
    } else {
      return null;
    }
  }

  componentClicked(): void {
    if (this.config.key == 'sms_verification_code') {
      this.helperService.setKeyboardInputConfig(this.config);
    } else {
      this.helperService.setKeyboardInputConfig(this.config.childFields[1]);
    }
  }

  setPhoneVerificationForm(phoneNumberForm: FormGroup): void {
    if (!phoneNumberForm) return;
    if (this.componentCaller === 'register') {
    }
    const phoneNumberData = phoneNumberForm.getRawValue();

    const phoneVerificationData = {
      phone_number: phoneNumberData,
      sms_verification_code: ''
    };
  }

  focusFirstOtpBox(): void {
    const firstElement = this.ngOtpInput.getBoxId(0);
    setTimeout(() => {
      const first = document.getElementById(firstElement);
      if (first) {
        first.focus();
        this.componentClicked();
      }
    }, 0);
  }

  resendSmsCode(): void {
    const phoneData = this.registrationFormStepOne.getRawValue();
    const phoneNumber = this.registrationService.formatPhoneNumber(phoneData.phone_number);
    const payload = {
      phoneNumber: phoneNumber || ''
    };

    const resendSmsCodeSub = this.registrationService.sendSmsCode(payload).subscribe(
      response => {
        if (response && response.status === SendSmsCodeEnum.OK) {
          const successMsg = __('sms_code_sent_successfully_msg');
          this.notificationService.showMessage(successMsg, 'success', false, 3000);
          return;
        }
        const errorMsg = __('failed_to_send_sms_code_msg');
        this.notificationService.showMessage(errorMsg, 'error', false, 3000);
      },
      () => {
        const errorMsg = __('failed_to_send_sms_code_msg');
        this.notificationService.showMessage(errorMsg, 'error', false, 3000);
      }
    );
    this.allSubscriptions.push(resendSmsCodeSub);
  }

  ngOnDestroy(): void {
    for (const subscription of this.allSubscriptions) {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    }
  }
}
