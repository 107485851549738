import { Component, ElementRef, Input, ViewChild, AfterViewInit } from '@angular/core';
import QRCode from 'qrcode';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';
import { Router } from '@angular/router';
import { InfoBoxTemplate } from '@app/shared/models/info-box';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent implements AfterViewInit {
  @Input() boxContent: InfoBoxTemplate;

  @ViewChild('appQrCodePlaceHolder') appQrCode: ElementRef<HTMLElement>;
  @ViewChild('containerQrCodePlaceHolder')
  containerQrCode: ElementRef<HTMLElement>;

  /**
   *
   */
  constructor(private router: Router) {}

  ngAfterViewInit(): void {
    if (this.boxContent.appQrCode) {
      this.generateQrCode(this.boxContent.appQrCode, '60%', this.appQrCode);
    }

    if (this.boxContent.qrCodeContainer && this.boxContent.qrCodeContainer.qr) {
      this.generateQrCode(this.boxContent.qrCodeContainer.uri, '60%', this.containerQrCode);
    }
  }

  navigateTo(path: string) {
    this.router.navigate([path], { queryParamsHandling: 'merge' });
  }

  generateQrCode(qrCodeUri: string, width: string, htmlRefElement: ElementRef<HTMLElement>): void {
    QRCode.toCanvas(qrCodeUri, {
      margin: 1,
      width
    })
      .then(canvas => {
        htmlRefElement.nativeElement.innerHTML = '';
        htmlRefElement.nativeElement.appendChild(canvas);
      })
      .catch(error => {
        console.warn(error);
      });
  }
}
